<template>
	<div class="container">

		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'projects-index'}">Projects</router-link>
				</li>

				<li class="breadcrumb-item active">
					New
				</li>
			</ol>
		</nav>

		<h3>New project</h3>

		<form @submit.prevent="save">
			<div class="form-group">
				<h5>Project code</h5>
				<input v-model="model.code" type="text" class="form-control" placeholder="0001-AM">
			</div>

			<div class="form-group">
				<h5>Project name</h5>
				<input v-model="model.name" type="text" class="form-control" placeholder="Aliens & Mushrooms">
			</div>

			<div class="form-group">

				<div class="form-check">
					<input class="mr-2" type="radio" value="null" v-model="model.cloneFrom"> Start from scratch
				</div>
				<hr>
				<h5>Clone from</h5>
				<div class="form-check d-flex align-items-center" v-for="project in projects">
					<input class="mr-2" :id="`clone-${project.Uuid}`" type="radio" :value="project.Uuid"
						   v-model="model.cloneFrom">
					<label :for="`clone-${project.Uuid}`">{{project.Name}}<br/><small class="text-light"
																					  v-rel-date="project.UpdatedAt"></small></label>
				</div>

			</div>

			<div class="form-group">
				<h5>Visibility</h5>

				<div class="custom-control custom-radio">
					<input type="radio" v-model="model.visibility" value="private" class="custom-control-input mr-2">
					<label class="custom-control-label"><i class="fa fa-lock text-primary"/> Private <span
							class="text-muted">Only you can access this project</span></label>
				</div>
				<div class="custom-control custom-radio">
					<input type="radio" v-model="model.visibility" value="public" class="custom-control-input mr-2">
					<label class="custom-control-label"><i class="fa fa-unlock text-secondary"/> Public <span
							class="text-muted">Project accessible by all users</span></label>
				</div>
			</div>

			<button class="btn btn-primary px-4" type="button" :disabled="loading">
				<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-if="loading"></span>
				<button type="submit" class="btn btn-primary px-4">SAVE</button>
			</button>
		</form>
	</div>
</template>

<script>
	import api from '@/api/'

	export default {
		data() {
			return {
				model: {
					code: null,
					name: null,
					visibility: "public",
					cloneFrom: null
				},
				loading: false
			}
		},
		methods: {
			async save() {
				this.loading = true;
				let project = await api.Projects.saveNewProject(this.model)
						.then(project => {
							this.$store.dispatch('Projects/load')
							this.$router.push({
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							})
							return project
						})
						.catch(() => this.loading = false);
			}
		},
		computed: {
			projects() {
				return this.$store.getters["Projects/list"]
			}
		}
	}
</script>
